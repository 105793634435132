<template>
  <b-row class="mb-2">
    <b-col cols="12" md="3">
      {{ name }}
    </b-col>
    <b-col cols="12" md="9">
      <b-row>
        <b-col v-for="(v, i) in result" :key="i">
          <ValidationProvider
              v-slot="{ errors }"
              :name="name"
              :rules="{
                required: disabled === false,
                numeric: true,
                length: digit
              }"
          >
            <b-form-input
                v-model="result[i]"
                :disabled="disabled"
                :maxlength="digit"
                :minlength="digit"
                :placeholder="`ผล${name} - ${digit} หลัก`"
                :state="errors[0] ? false : null"
                required
                type="text"
            ></b-form-input>
          </ValidationProvider>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'LottoResultField',
  props: {
    value: {
      type: [String, Array],
      required: true,
    },
    name: {
      type: String,
      required: true
    },
    digit: {
      type: [Number, String],
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: () => ({
    result: []
  }),
  watch: {
    result: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      }
    },
    value(val) {
      this.result = this.transformValue(val)
    }
  },
  created() {
    this.result = this.transformValue(this.value)
  },
  methods: {
    transformValue(val) {
      return Array.isArray(val) ? val : [val]
    }
  }
}
</script>

<style scoped>

</style>