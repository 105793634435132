<template>
  <div>
    <PageHeader />
    <b-card v-if="isFetchingLastGameRound" class="text-center">
      <b-spinner />
    </b-card>
    <b-card v-else>
      <h4 class="mb-4">{{ lastGameRound.name }}</h4>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form id="resultForm" @submit.prevent="handleSubmit(onSubmitForm)">
          <b-row cols="1" cols-lg="2">
            <b-col class="border-right border-bottom">
              <h5>กรอกผลรางวัล</h5>
              <LottoResultGovernment v-if="lottoGame.gameType === LottoGameTypeEnum.THAI_GOVERNMENT" v-model="resultNumber" />
              <LottoResultSet v-else-if="lottoGame.gameType === LottoGameTypeEnum.SET" v-model="resultNumber" />
              <LottoResultGeneral v-else v-model="resultNumber" />
            </b-col>
            <b-col class="border-bottom">
              <b-overlay :show="!isCalculated" spinner-type="">
                <LottoResultCalculated />
              </b-overlay>
            </b-col>
          </b-row>
          <hr />
          <div class="text-right">
            <b-button :to="lottoListUrl" class="mr-3" variant="white">
              <i class="uil uil-arrow-left"></i>
              {{ $t('buttons.back') }}
            </b-button>
            <b-button :disabled="isSubmitting" class="mr-3" variant="danger" @click="showModalRefund()">
              <i class="uil uil-repeat"></i>
              {{ $t('buttons.cancel_lotto') }}
            </b-button>
            <b-button v-if="isCalculated" :disabled="isSubmitting" class="mr-3" variant="primary" @click.prevent="reCalculate">
              <i class="uil uil-refresh"></i>
              {{ $t('buttons.re_calculate') }}
            </b-button>
            <b-button v-else :disabled="isSubmitting" class="mr-3" type="submit" variant="primary">
              <i class="uil uil-calculator"></i>
              {{ $t('buttons.calculate') }}
            </b-button>
            <b-button
              v-if="isCalculated"
              :disabled="isSubmitting"
              class="mr-3"
              type="submit"
              variant="success"
            >
              <i class="uil uil-check"></i>
              {{ $t('buttons.confirm_result') }}
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-card>
    <b-modal
        v-if="!isFetchingLastGameRound"
        id="lotto-cancel-confirm-modal"
        :title="cancelLottoTitle"
        cancel-title="ยกเลิก"
        cancel-variant="white"
        ok-title="ยืนยัน"
        @ok="cancelLotto()"
    >
      <p
        >คุณยืนยันที่จะคืนโพยใช่หรือไม่ หากยืนยันโปรดพิมพ์
        <u class="font-weight-bold">คืนโพย{{ lottoGame.name }}</u> ลงในช่อง</p
      >
      <b-input v-model="confirmInput" :placeholder="`คืนโพย${lottoGame.name}`" />
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {getBetTypeLabel} from './utils'
import LottoResultCalculated from '@components/lotto/lotto-result-calculated';
import {LottoGameRoundStatusEnum, LottoGameTypeEnum} from '@src/constants/lotto';
import dayjs from 'dayjs';
import LottoResultGovernment from '@components/lotto/result/government';
import LottoResultGeneral from '@components/lotto/result/general';

export default {
  page: {
    title: 'ออกผล',
  },
  components: {
    LottoResultGeneral,
    LottoResultGovernment,
    LottoResultCalculated,
  },
  data() {
    return {
      resultNumber: {},
      cancelLottoTitle: '',
      confirmInput: '',
      isSubmitting: false
    }
  },
  computed: {
    ...mapState({
      isFetchingLastGameRound: (state) => state.lotto.isFetchingLastGameRound,
      isCalculated: (state) => state.lotto.isCalculated,
    }),
    ...mapGetters([
      'lottoSummary',
      'lastGameRound',
    ]),
    lottoGameId() {
      return this.$route.params.id
    },
    lottoGame() {
      return this.lastGameRound?.lottoGame
    },
    resultNumberPayload() {
      return Object
        .entries(this.resultNumber)
        .map((result) => ({
          prizingType: result[0],
          winNumber: result[1]
        }))
    },
    lottoListUrl() {
      const type = this.$route.query.type || LottoGameTypeEnum.THAI_GOVERNMENT
      return `/lotto/list?type=${type}`
    },
    LottoGameTypeEnum() {
      return LottoGameTypeEnum
    }
  },
  async created() {
    if (!this.lottoGameId) {
      await this.$router.push(this.lottoListUrl)
    }

    this.resetLottoSummary()
    await this.fetchData()

    this.validateGameRoundStatus()
  },
  methods: {
    getBetTypeLabel,
    ...mapActions([
      'getSummaryResultByGameRoundId',
      'resetLottoSummary',
      'fetchLastGameRoundByGameId',
      'cancelAllLottoBetByGameRoundId',
      'submitLottoResult',
    ]),
    validateGameRoundStatus() {
      const isOpeningStatus = (gameRound) => gameRound.status === LottoGameRoundStatusEnum.CLOSE || gameRound.status === LottoGameRoundStatusEnum.OPENING
      const isEndedStatus = (gameRound) => gameRound.status === LottoGameRoundStatusEnum.REFUND || gameRound.status === LottoGameRoundStatusEnum.DONE
      if(!this.lastGameRound || isEndedStatus(this.lastGameRound)) {
        this.$router.push({
          path: `/lotto/${this.lottoGameId}/round`,
          query: {
            type: this.$route.query.type
          }
        })
      } else if(!isOpeningStatus(this.lastGameRound) || dayjs().isBefore(this.lastGameRound.resultDatetime)) {
        this.$router.push(this.lottoListUrl)
      }
    },
    async fetchData() {
      await this.fetchLastGameRoundByGameId(this.lottoGameId)
    },
    async onSubmitForm() {
      this.isSubmitting = true
      if(this.isCalculated) {
        await this.submitResult()
      } else {
        await this.calculateProfit()
      }
      this.isSubmitting = false
    },
    reCalculate() {
      this.resetLottoSummary()
    },
    showModalRefund() {
      let resultDate = new Date(this.lastGameRound.resultDatetime);
      resultDate = resultDate.toLocaleDateString()
      this.cancelLottoTitle = `คืนโพย ${this.lottoGame.name} รอบ ${resultDate}`
      this.$bvModal.show('lotto-cancel-confirm-modal')
    },
    cancelLotto() {
      if (this.confirmInput === `คืนโพย${this.lottoGame.name}`) {
        this.cancelAllLottoBetByGameRoundId(this.lastGameRound.id)
      }
    },
    async calculateProfit() {
      await this.getSummaryResultByGameRoundId({
        id: this.lastGameRound.id,
        payload: this.resultNumberPayload,
      })
    },
    async submitResult() {
      await new Promise((resolve) => {
        this.$bvModal
            .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
              okTitle: `${this.$t('buttons.confirm')}`,
              cancelTitle: `${this.$t('buttons.cancel')}`,
            })
            .then(async (confirm) => {
              if (confirm) {
                //call submit
                await this.submitLottoResult({
                  id: this.lastGameRound.id,
                  payload: this.resultNumberPayload,
                })

                await this.$router.push({
                  path: `/lotto/${this.lottoGameId}/round`,
                  query: {
                    type: this.$route.query.type
                  }
                })
              }
              resolve()
            })
      })
    },
  },
}
</script>
