<template>
  <div>
    <h5>คำนวณรายจ่าย</h5>
    <b-table :busy="isFetchingLottoSummary" :fields="fields" :items="betResult" show-empty>

      <template #cell(betAmount)="data">
        {{ data.value.toLocaleString() }}
      </template>

      <template #cell(winAmount)="data">
        {{ data.value.toLocaleString() }}
      </template>

      <template #cell(diffAmount)="data">
        <span :class="{ 'text-success': data.value > 0, 'text-danger': data.value < 0 }">
          {{ data.value > 0 ? '+' : ''}}{{ data.value.toLocaleString() }}
        </span>
      </template>

      <template #empty>
        <div class="text-muted text-center">
          ยังไม่ได้คำนวณผลรางวัล
        </div>
      </template>

      <template #table-busy>
        <div class="text-center">
          <b-spinner />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {getBetTypeLabel} from '@src/views/main/lotto/utils';

export default {
  name: 'LottoResultCalculated',
  data() {
    return {
      fields: [
        {
          key: 'betTypeName',
          label: 'ประเภท',
        },
        {
          key: 'betAmount',
          label: 'ยอดแทง',
        },
        {
          key: 'winAmount',
          label: 'ยอดถูก',
        },
        {
          key: 'diffAmount',
          label: 'ส่วนต่าง',
        }
      ]
    }
  },
  computed: {
    ...mapState({
      isFetchingLottoSummary: (state) => state.lotto.isFetchingLottoSummary
    }),
    ...mapGetters(['lottoSummary']),
    betResult() {
      const summary = {
        betAmount: 0,
        winAmount: 0,
        diffAmount: 0
      }
      const betResult = Object
          .entries(this.lottoSummary.betResult)
          .map((result) => {
            const {totalBetAmount, totalWinAmount} = result[1]
            const totalDiffAmount = totalBetAmount - totalWinAmount
            summary.betAmount += totalBetAmount
            summary.winAmount += totalWinAmount
            summary.diffAmount += totalDiffAmount
            return {
              betTypeName: this.getBetTypeLabel(result[0]),
              betAmount: totalBetAmount,
              winAmount: totalWinAmount,
              diffAmount: totalDiffAmount,
            }
          })
      if(betResult.length) {
        const affiliateAmount = Number(this.lottoSummary.affiliateAmount)
        summary.diffAmount -= affiliateAmount
        betResult.push({
          betTypeName: 'ค่าคอมฯ แนะนำเพื่อน',
          betAmount: '',
          winAmount: '',
          diffAmount: affiliateAmount >= 0 ? affiliateAmount * -1 : affiliateAmount
        })
        betResult.push({
          betTypeName: 'รวม',
          ...summary
        })
      }
      return betResult
    }
  },
  methods: {
    getBetTypeLabel
  }
}
</script>

<style scoped>

</style>