<template>
  <div>
    <LottoResultField v-model="form[LottoPrizingTypeEnum.FIRST_PRIZE]" digit="6" name="รางวัลที่ 1" />
    <LottoResultField :value="threeTop" digit="3" disabled name="สามตัวบน" />
    <LottoResultField v-model="form[LottoPrizingTypeEnum.THREE_FRONT]" digit="3" name="สามตัวหน้า" />

    <LottoResultField v-model="form[LottoPrizingTypeEnum.THREE_UNDER]" digit="3" name="สามตัวหลัง" />

    <LottoResultField :value="twoTop" digit="2" disabled name="สองตัวบน" />
    <LottoResultField v-model="form[LottoPrizingTypeEnum.TWO_UNDER]" digit="2" name="สองตัวล่าง" />
  </div>
</template>

<script>

import LottoResultField from '@components/lotto/result/field';
import {LottoPrizingTypeEnum} from '@src/constants/lotto';

export default {
  name: 'LottoResultGovernment',
  components: {LottoResultField},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    form: {}
  }),
  computed: {
    LottoPrizingTypeEnum() {
      return LottoPrizingTypeEnum
    },
    threeTop() {
      const firstPrize = this.form[LottoPrizingTypeEnum.FIRST_PRIZE][0]
      return firstPrize.length === 6 ? firstPrize.substring(3 , 6) : ''
    },
    twoTop() {
      const firstPrize = this.form[LottoPrizingTypeEnum.FIRST_PRIZE][0]
      return firstPrize.length === 6 ? firstPrize.substring(4 , 6) : ''
    }
  },
  watch: {
    form: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    this.form = {
      [LottoPrizingTypeEnum.FIRST_PRIZE]: [''],
      [LottoPrizingTypeEnum.THREE_FRONT]: ['', ''],
      [LottoPrizingTypeEnum.THREE_UNDER]: ['', ''],
      [LottoPrizingTypeEnum.TWO_UNDER]: [''],
    }
  }
}
</script>

<style scoped>

</style>